html,
body,
#root,
#cesiumContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#cesiumContainer {
  overflow: hidden;
}
